import "bootstrap";
// import '@/assets/javascripts/vendor/formstone-dropdown';
// import '@/assets/javascripts/vendor/formstone-checkbox';
import "@/assets/javascripts/clients/charts/ScatterChartTimeBuilder.js";
import "@/assets/javascripts/clients/charts/donutChart.js";
import "@/assets/javascripts/clients/charts/scatterChart.js";
import "@/assets/javascripts/milkshake/nav.js";
// import '@/assets/javascripts/application/reports.js.coffee';

$(function() {
  $(".button-link").click(function(e) {
    const $elm = $(this);
    const url = $elm.data("url");

    window.location = url;
  });

  try { $('[data-toggle="tooltip"]').tooltip(); } catch (e) {}
  try { $("select.form-control").dropdown(); } catch (e) {}
  // $('[data-toggle="datepicker"]').datepicker({ autoHide: true });
  // $("input[type=checkbox], input[type=radio]").checkbox();
});
